import React from 'react';
import moment from 'moment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useAppSelector } from 'src/store';
import { getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import { availableAreaColors } from 'src/shared/constants/colors';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { useSurveyDesignsQuery } from 'src/app/surveys/state/api/surveyGraphSlice';
import Loader from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Access, ProjectSamplingCadenceType, Resources } from 'src/shared/types';
import { SecondaryButton } from 'src/shared/components/button/Button';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from '../new-survey-design/components/review-and-submit/ReviewAndSubmit.module.scss';
import ReviewSamples from '../new-survey-design/components/review-and-submit/components/ReviewSamples';

const SurveyDesignSummary = () => {
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails) || { customerName: '', customerId: '' };
    const { currentProjectId } = useProject();
    const { hasPermission } = usePermissions();
    const navigate = useAppNavigation();

    const canAdminSurveyDesign = hasPermission({
        to: [Access.CREATE, Access.UPDATE],
        customerId: currentCustomerDetails.customerId,
        resource: Resources.SURVEY_DESIGN,
    });

    const { isFetching, currentData } = useSurveyDesignsQuery(
        {
            customerId: currentCustomerDetails.customerId,
        },
        {
            skip: !currentCustomerDetails.customerId,
        }
    );

    if (isFetching) {
        return <Loader />;
    }

    if (!currentData) {
        return null;
    }

    const survey = currentData.listSurveyDesigns.find(entry => entry.projectId === currentProjectId);

    if (!survey) {
        return null;
    }
    const {
        projectName,
        projectCode,
        subscription,
        country,
        area,
        leadRole,
        projectType,
        projectOutline,
        surveyPublishDate,
        targetOutcome,
        samplingCadence,
        surveySamples,
        sampleGroups,
        speciesOfInterest,
        habitatAssayTypes,
        leadEmail,
        leadName,
    } = survey;

    const mainContent = (
        <>
            <div className={styles.reviewAndSubmit}>
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Survey details</span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Customer name</div>
                            <div className={styles.value}>{currentCustomerDetails.customerName}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Survey design name</div>
                            <div className={styles.value}>{projectName}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Project code</div>
                            <div className={styles.value}>{projectCode}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Subscription type</div>
                            <div className={styles.value}>{subscription?.label}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Survey publish date</div>
                            <div className={styles.value}>{moment(surveyPublishDate).format(STANDARD_DATE_FORMAT)}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Sampling country</div>
                            <div className={styles.value}>{country}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Area</div>
                            <div className={styles.value}>{area}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Customer contact</div>
                            <div className={styles.customerContact}>
                                <div className={styles.userIcon}>
                                    <PersonOutlineOutlinedIcon />
                                </div>
                                <div className={styles.contactDetails}>
                                    <div className={styles.contactDetailsName}>{leadName}</div>
                                    <div>{leadEmail}</div>
                                </div>
                                <div className={styles.role}>
                                    <div>{leadRole}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Survey aims</span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Survey design type <QuestionButtonHelp type='api' slug='project-definition-project-type' />
                            </div>
                            <div className={styles.value}>{projectType}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Project outline</div>
                            <div className={styles.value}>{projectOutline}</div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Target outcomes</div>
                            <div className={styles.value}>{targetOutcome}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Sample groups <QuestionButtonHelp type='api' slug='project-definition-sample-groups' />
                            </div>
                            <div className={styles.sampleGroups}>
                                {sampleGroups.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.sampleGroupTag}>
                                            <div
                                                className={styles.sampleGroupTagColor}
                                                style={{ backgroundColor: availableAreaColors[index] }}
                                            ></div>
                                            <div className={styles.sampleGroupTagValue}>{entry}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Species of interest</div>
                            <div className={styles.sampleGroups}>
                                {speciesOfInterest.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.sampleGroupTag}>
                                            <div className={styles.sampleGroupTagValue}>{entry}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Test <QuestionButtonHelp type='api' slug='project-definition-habitat-test-type' />
                            </div>
                            <div className={styles.habitatAssayTypes}>
                                {habitatAssayTypes.map((entry, index) => {
                                    return (
                                        <div key={index} className={styles.habitatAssayTypeTag}>
                                            <div key={index} className={styles.habitatAssayTypeTagValue}>
                                                <div className={styles.habitatName}>{entry.habitatName}</div>
                                                <div className={styles.assayName}>{entry.assayName}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Sampling cadence</span>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.sectionItem}>
                            <div className={styles.label}>Multi year or single year sampling</div>
                            <div className={styles.value}>
                                {samplingCadence?.type === ProjectSamplingCadenceType.SINGLE_YEAR ? 'Single year' : 'Multi year'}
                            </div>
                        </div>

                        <div className={styles.sectionItem}>
                            <div className={styles.label}>
                                Sampling events per year <QuestionButtonHelp type='api' slug='sampling-events-per-year' />
                            </div>
                            <div className={styles.value}>{samplingCadence?.samplingEventsPerYear}</div>
                        </div>
                    </div>
                    <div className={styles.sectionItems}>
                        <div className={styles.samplingEvents}>
                            {samplingCadence?.events.map((entry, index) => {
                                return (
                                    <div key={index} className={styles.samplingEvent}>
                                        <div className={styles.eventNumber}>
                                            Sampling event {index + 1}{' '}
                                            <QuestionButtonHelp type='api' slug='project-definition-sampling-event' />
                                        </div>
                                        <div className={styles.eventName}>{entry.name}</div>
                                        <div className={styles.datesTitle}>Expected dates</div>
                                        <div className={styles.dates}>
                                            <span className={styles.dateLabel}>From</span>
                                            <span className={styles.dateValue}>{moment(entry.fromDate).format(STANDARD_DATE_FORMAT)}</span>
                                            <span className={styles.dateLabel}>To</span>
                                            <span className={styles.dateValue}>{moment(entry.toDate).format(STANDARD_DATE_FORMAT)}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                        <span>Samples</span>
                    </div>
                    <ReviewSamples sampleGroups={sampleGroups} surveySamples={surveySamples} />
                </div>
            </div>
        </>
    );

    const otherActions = (
        <div className={styles.otherActions}>
            {canAdminSurveyDesign && <SecondaryButton onClick={navigate.toSurveyAdmin}>Go to survey design admin</SecondaryButton>}
        </div>
    );

    return <StandardAppLayout title='Survey design summary' subTitle={projectName} mainContent={mainContent} otherActions={otherActions} />;
};

export default SurveyDesignSummary;
