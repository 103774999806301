import React, { useEffect } from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';

import useScrollToTop from 'src/shared/hooks/useScrollToTop';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { SecondaryButton } from 'src/shared/components/button/Button';
import { SaveOutlined } from '@mui/icons-material';
import Confirm from 'src/shared/components/confirm/Confirm';
import WarningMessage from 'src/shared/components/warning-message/WarningMessage';
import { useAppDispatch } from 'src/store';
import StepIndicator from 'src/shared/components/step-indicator/StepIndicator';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import BottomNavigation from './components/bottom-navigation/BottomNavigation';
import SamplingCadence from './components/sampling-cadence/SamplingCadence';
import ReviewAndSubmit from './components/review-and-submit/ReviewAndSubmit';
import useSurveyDesign from './hooks/useSurveyDesign';
import styles from './NewSurveyDesign.module.scss';
import SurveyDesignDetails from './components/survey-design-details/SurveyDesignDetails';
import SurveyDesignAims from './components/survey-design-aims/SurveyDesignAims';
import { reset } from './state/newSurveyDesignSlice';
import SurveySamples from './components/survey-samples/SurveySamples';

export const NewSurveyDesign = () => {
    const dispatch = useAppDispatch();
    const { currentCustomerId } = useCustomer();
    const navigate = useAppNavigation();
    const { formStep, saveSurveyDesignForm, canCurrentUserContinueForm } = useSurveyDesign();

    useEffect(() => {
        if (!currentCustomerId) {
            navigate.toCustomers();
        }
        dispatch(reset());
    }, [currentCustomerId]);

    const ref = useScrollToTop(formStep);

    const mainContent = (
        <div className={styles.surveyDesign} ref={ref}>
            <StepIndicator
                currentStep={formStep}
                steps={['Survey details', 'Survey aims', 'Sampling cadence', 'Samples', 'Review and submit']}
            />
            {formStep === 4 && <ReviewAndSubmit />}
            <div className={styles.formSection}>
                {formStep === 0 && <SurveyDesignDetails />}
                {formStep === 1 && <SurveyDesignAims />}
                {formStep === 2 && <SamplingCadence />}
                {formStep === 3 && <SurveySamples />}
                {formStep !== 4 && <BottomNavigation />}
            </div>
        </div>
    );

    const onSave = () => {
        saveSurveyDesignForm();
        return;
    };

    const saveMessage = (
        <WarningMessage
            message={
                <>
                    This survey design is NOT submitted.
                    <br />
                    <br />
                    It will be retained with ‘incomplete’ status for 5 days, after which it will be deleted.
                    <br />
                    <br />
                    This incomplete survey design will only be editable by you.
                </>
            }
        />
    );

    const otherActions = formStep !== 4 && canCurrentUserContinueForm && (
        <div className={styles.otherActions}>
            {/* TODO: Uncomment when we have delete feature
                <Confirm
                onConfirm={onDelete}
                title='Are you sure you want to delete this survey design?'
                content='Once deleted, this survey design cannot be retrieved'
                width={'500px'}
            >
                <SecondaryButton>
                    <DeleteOutline />
                    Delete
                </SecondaryButton>
            </Confirm> */}

            <Confirm
                onConfirm={onSave}
                title='Are you sure you want to save and exit from this survey design?'
                content={saveMessage}
                width={'500px'}
            >
                <SecondaryButton>
                    <SaveOutlined />
                    Save and exit
                </SecondaryButton>
            </Confirm>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} otherActions={otherActions} title='New survey design' />;
};

export default NewSurveyDesign;
