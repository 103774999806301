import React, { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import useAuthService from 'src/shared/hooks/useAuthService';
import Input from 'src/shared/components/input/Input';
import { PrimaryButton } from 'src/shared/components/button/Button';

import Loader from 'src/shared/components/loader/Loader';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from './Signin.module.scss';

const VerifyCustomChallenge = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { verifyCustomChallengeCode, resendCustomChallengeCode, customChallengeParameters, cognitoUser } = useAuthService();
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useAppNavigation();

    const onFailure = (e: Error) => {
        const message = e.stack || '';
        setSuccessMessage('');
        if (message.includes('CodeMismatchException')) {
            setErrorMessage('Verification code was incorrect. Click resend code to try with a new code');
        } else if (message.includes('ExpiredCodeException') || message.includes('NotAuthorizedException')) {
            setErrorMessage('The verification code has expired. Please request a new one.');
        } else if (message.includes('LimitExceededException')) {
            setErrorMessage('You have reached the limit for requesting a new code.');
        } else if (message.includes('CodeDeliveryFailureException')) {
            setErrorMessage('The verification code has failed to deliver.');
        } else {
            setErrorMessage(message);
        }
    };

    const handleVerificationCode = (e: React.FormEvent) => {
        e.preventDefault();
        verifyCustomChallengeCode({ verificationCode: verificationCode, onFailure });
    };

    useEffect(() => {
        if (!cognitoUser) {
            navigate.toHome();
        }
    }, [cognitoUser]);

    const resendCode = () => {
        setSuccessMessage('Verification code has been resent.');
        resendCustomChallengeCode({
            onSuccess: () => setSuccessMessage('Verification code has been resent.'),
            onFailure,
        });
    };

    if (!customChallengeParameters) {
        return <Loader />;
    }

    const title = (
        <>
            Enter the verification code sent to your registered{' '}
            {customChallengeParameters.mode === 'sms' ? 'phone number' : 'email address'}:{' '}
            <div className={styles.phoneOrEmail}>{customChallengeParameters.identity}</div>
        </>
    );

    return (
        <div className={styles.formContainer}>
            <div className={styles.subTitle}>{title}</div>
            <form className={styles.forgotPasswordForm} onSubmit={handleVerificationCode}>
                <Input
                    placeholder='Verification Code*'
                    onChange={e => setVerificationCode(e.target.value)}
                    value={verificationCode}
                    required
                />

                <div onClick={resendCode} className={styles.resendLink}>
                    Resend code
                </div>

                <PrimaryButton type='submit'>Submit</PrimaryButton>
            </form>
            {errorMessage && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity='error' sx={{ color: '#f00' }}>
                        {errorMessage.toString()}
                    </Alert>
                </Stack>
            )}

            {successMessage && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity='success'>{successMessage}</Alert>
                </Stack>
            )}
        </div>
    );
};

export default VerifyCustomChallenge;
