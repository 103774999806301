import React from 'react';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAdminRoutesAvailability, getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { adminRouteSettings } from 'src/app/admin/Admin';
import useAuthService from 'src/shared/hooks/useAuthService';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from '../SiteMenu.module.scss';

const AdminMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const adminRoutesAvailability = useAppSelector(getAdminRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();
    const { isCurrentUserSuperAdmin } = useAuthService();

    if (!appRoutesAvailability.admin) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: menuItemPath.startsWith(pathname),
        });
    };

    const adminMenuIcon = isCurrentUserSuperAdmin ? <PsychologyOutlinedIcon /> : <Person2OutlinedIcon />;
    const adminMenuText = isCurrentUserSuperAdmin ? 'Super admin' : adminRouteSettings.userManagement.name;

    return (
        <>
            {adminRoutesAvailability.userManagement && (
                <div className={getClassNames(adminRouteSettings.userManagement.path)} onClick={navigate.toUserManagement}>
                    <div className={styles.icon}>{adminMenuIcon}</div>
                    <div className={styles.label}>{adminMenuText}</div>
                </div>
            )}
        </>
    );
};

export default AdminMenu;
