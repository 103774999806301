import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { appRouteSettings } from 'src/routes/AppRoutes';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from '../SiteMenu.module.scss';

const DashboardMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();

    if (!appRoutesAvailability.dashboard) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: menuItemPath.startsWith(pathname),
        });
    };

    return (
        <>
            <div className={getClassNames(appRouteSettings.dashboard.path)} onClick={navigate.toDashboard}>
                <div className={styles.icon}>
                    <DashboardOutlinedIcon />
                </div>
                <div className={styles.label}>{appRouteSettings.dashboard.name}</div>
            </div>
        </>
    );
};

export default DashboardMenu;
