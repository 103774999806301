import React, { ReactNode } from 'react';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Role, UserType } from 'src/shared/types';
import { useAppSelector, useAppDispatch } from 'src/store';
import { getNewProjectUserFormStep, getNewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSelector';
import { reset, setNewUserView, updateGeneralUiState, setFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import {
    useCreateProjectUserMutation,
    useCreateUserMutation,
    useLazyGetCustomerAdminUsersQuery,
    useAdminUpdateUserMutation,
} from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import { SecondaryButton, PrimaryButton } from 'src/shared/components/button/Button';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import useAuthService from 'src/shared/hooks/useAuthService';
import useCheckUserExists from 'src/app/admin/views/user-management/hooks/useCheckUserExists';
import { uniq } from 'lodash';
import styles from './FormNavigation.module.scss';

const FormNavigation = () => {
    const dispatch = useAppDispatch();
    const newUserView = useAppSelector(getNewProjectUserFormStep);
    const formState = useAppSelector(getNewProjectUserFormState);
    const { errorMessage } = formState;

    const { currentCustomerId } = useCustomer();
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();
    const userAlreadyExists = useCheckUserExists(formState.selectedUser);

    const [createProjectUser] = useCreateProjectUserMutation();
    const [createUser] = useCreateUserMutation();
    const [getCustomerAdminUsers] = useLazyGetCustomerAdminUsersQuery();
    const [adminUpdateUser] = useAdminUpdateUserMutation();

    const setErrorMessage = (message: ReactNode) => {
        dispatch(setFormState({ errorMessage: message }));
    };

    const validateCustomerAdminAddition = (customerAdminCount: number) => {
        if (isCurrentUserOnboardingManager && customerAdminCount > 0) {
            const customerAdminValidationMessage = (
                <>
                    Another onboarding manager has already created the customer admin for this organisation.
                    <p>Close this side panel to return to the main page.</p>
                </>
            );
            setErrorMessage(customerAdminValidationMessage);
            dispatch(setFormState({ disableSubmit: true }));
            return false;
        }

        return true;
    };

    const submitForm = async () => {
        const {
            firstName,
            lastName,
            phoneCountryCode = '',
            phone = '',
            email,
            customers = [],
            projects: existingProjects,
        } = formState.selectedUser ? formState.selectedUser : { ...formState, customers: [] };
        const { projects } = formState;

        const statusMessage = formState.selectedUser ? 'Existing user successfully added' : 'New user successfully created';
        const phoneNumber = formState.selectedUser ? formState.selectedUser.phone : phoneCountryCode + phone;

        if (
            ![firstName, lastName, email, (projects.length && projects.every(entry => entry.projectId)) || formState.roles].every(Boolean)
        ) {
            return;
        }

        try {
            // De-duplicate projects and merge roles
            const allProjects = [...(existingProjects || []), ...projects].filter(Boolean);
            const projectMap = allProjects.reduce((map, { projectId, roles = [] }) => {
                const existingRoles = map.get(projectId) || [];
                map.set(projectId, uniq([...existingRoles, ...(roles || [])]).filter(Boolean));
                return map;
            }, new Map<string, string[]>());

            const projectsToSet = Array.from(projectMap).map(([projectId, roles]) => ({
                projectId,
                roles,
            }));

            if (isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) {
                const isInternalUser = formState.userType === UserType.INTERNAL;
                if (formState.roles.includes(Role.CUSTOMER_ADMIN)) {
                    const customerAdminData = await getCustomerAdminUsers({
                        customerId: currentCustomerId,
                    }).unwrap();
                    const customerAdminCount = customerAdminData?.customerAdmins.length || 0;
                    if (!validateCustomerAdminAddition(customerAdminCount)) {
                        return;
                    }
                }

                const commonParams = {
                    firstName,
                    lastName,
                    phone: phoneNumber,
                    customers: [
                        {
                            customerId: currentCustomerId,
                            roles: [...formState.roles],
                        },
                        ...customers.filter(customer => customer.customerId !== currentCustomerId),
                    ],
                    projects: projectsToSet,
                };

                if (formState.selectedUser) {
                    await adminUpdateUser({
                        ...commonParams,
                        tnc_consent: true,
                        cognitoId: formState.selectedUser.cognitoId,
                    }).unwrap();
                } else {
                    await createUser({
                        ...commonParams,
                        email,
                        tnc_consent: false,
                        isNatureMetricsUser: isCurrentUserSuperAdmin && isInternalUser,
                    }).unwrap();
                }
            } else {
                await createProjectUser({
                    firstName,
                    lastName,
                    phone: phoneNumber,
                    email,
                    projects: projectsToSet,
                    customerId: currentCustomerId,
                }).unwrap();
            }

            dispatch(reset());
            dispatch(
                updateGeneralUiState({
                    shouldShowNewUserModal: false,
                    shouldShowSuccessAlert: true,
                    successStatusMessage: statusMessage,
                })
            );
        } catch (error: any) {
            console.error('rejected', error);

            const message = (error?.message as string) || error?.meta?.error || 'Something went wrong';

            if (message.includes('UsernameExistsException')) {
                setErrorMessage('A user with the given email ID already exists.');
            } else {
                setErrorMessage(message);
            }
        }
    };

    const onStepChange = (step: string) => {
        setErrorMessage('');
        dispatch(setNewUserView(step));
    };

    const canProceedToStepTwo = () => {
        // If the user has selected an existing user, we can proceed to step 2
        if (formState.selectedUser && !userAlreadyExists) {
            return true;
        }
        return [
            formState.firstName,
            formState.lastName,
            formState.email,
            formState.isEmailValid,
            formState.emailIdAvailabilityChecked,
            formState.emailIdAvailable,
            !formState.phoneCountryCode || isFinite(Number(formState.phoneCountryCode)),
            !formState.phone || isFinite(Number(formState.phone)),
        ].every(Boolean);
    };

    const canSubmitForm = () => {
        const canSubmitArray: boolean[] = formState.projects.map(entry => {
            return (entry.roles?.length || 0) > 0;
        });

        const hasValidProjects = formState.projects.length && formState.projects.every(entry => entry.projectId);
        const canSubmit = canSubmitArray.every(Boolean) && !formState.disableSubmit;
        const hasRoles = formState.roles.length > 0;
        return canProceedToStepTwo() && ((hasValidProjects && canSubmit) || hasRoles);
    };

    return (
        <>
            <div className={styles.bottomNavigation}>
                {newUserView === 'default' && (
                    <>
                        <div></div>
                        <PrimaryButton onClick={() => onStepChange('project-permissions')} disabled={!canProceedToStepTwo()}>
                            <span className={styles.buttonText}>Project permissions</span> <ChevronRight />
                        </PrimaryButton>
                    </>
                )}

                {newUserView === 'project-permissions' && (
                    <>
                        <SecondaryButton onClick={() => onStepChange('default')}>
                            <ChevronLeft /> <span className={styles.buttonText}>Back</span>
                        </SecondaryButton>
                        <PrimaryButton onClick={submitForm} disabled={!canSubmitForm()}>
                            <SaveOutlinedIcon />{' '}
                            <span className={styles.buttonText} style={{ marginLeft: '8px' }}>
                                Save new user
                            </span>
                        </PrimaryButton>
                    </>
                )}
            </div>

            <div>{errorMessage && <ErrorMessage message={errorMessage} />}</div>
        </>
    );
};

export default FormNavigation;
